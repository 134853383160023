import { storeToRefs } from 'pinia'
import { PAGE } from './router'
import { useGameStore } from '~/store/game'
import { LOBBY_GAME_URLS, PAGE_URLS, SPORTS_URLS } from '~~/config/page-url'
import { useGame } from '~/composables/game/useGame'

export const getHeaderMenu = () => {
  const { $pinia } = useNuxtApp()
  const storeGame = useGameStore($pinia)
  const { providerGames, providerCasino } = storeToRefs(storeGame)
  const {
    fetchCongGame
  } = useGame()
  if (Object.keys(providerGames.value).length === 0) {
    fetchCongGame()
  }
  const firstAlias = computed(() => {
    if (providerGames.value && Array.isArray(providerGames.value) && providerGames.value.length > 0) {
      return providerGames.value?.[0].alias
    }
    return 'table-game'
  })

  return {
    HEADER_MENU: [
      {
        id: 'sport',
        title: 'Thể Thao',
        icon: '/assets/images/components/desktop/icon-sport.png',
        loginRequired: false,
        newTab: false,
        link: PAGE.SPORTS,
        route: PAGE.SPORTS,
        type: 'sport',
        subMenu: [
          {
            title: 'K-SPORTS',
            label: 'hot',
            image: '/assets/images/components/desktop/home/header/k-sport.png',
            loginRequired: false,
            newTab: false,
            url: SPORTS_URLS.K_SPORT
          },
          {
            title: 'C-SPORTS',
            label: '',
            image: '/assets/images/components/desktop/home/header/c-sport.png',
            loginRequired: false,
            newTab: false,
            url: SPORTS_URLS.C_SPORT
          },
          {
            title: 'BTI-SPORTS',
            label: '',
            image: '/assets/images/components/desktop/home/header/bti-sport.png',
            loginRequired: false,
            newTab: false,
            url: SPORTS_URLS.P_SPORT
          },
          {
            title: 'Thể thao ảo',
            image: '/assets/images/components/desktop/home/header/v-sport.png',
            loginRequired: false,
            newTab: false,
            url: SPORTS_URLS.VIRTUAL_SPORTS
          },
          {
            title: 'E-Sports',
            label: '',
            image: '/assets/images/components/desktop/home/header/e-sport.png',
            loginRequired: false,
            newTab: false,
            url: SPORTS_URLS.E_SPORT
          },
          {
            title: 'Volta',
            label: '',
            image: '/assets/images/components/desktop/home/header/volta-sport.png',
            loginRequired: false,
            newTab: false,
            url: SPORTS_URLS.VOLTA_SPORT
          },
          {
            title: 'Schedule',
            label: '',
            image: '/assets/images/components/desktop/home/header/schedule.png',
            loginRequired: false,
            newTab: false,
            url: PAGE_URLS.SCHEDULES
          }
        ]
      },
      {
        id: 'live-casino',
        title: 'Live Casino',
        icon: '/assets/images/components/desktop/icon-live-casino.png',
        loginRequired: false,
        newTab: false,
        link: PAGE.CASINO,
        route: PAGE.CASINO,
        type: 'casino'
      },
      {
        id: 'games',
        title: 'Cổng Game',
        icon: '/assets/images/components/desktop/icon-games.png',
        loginRequired: true,
        newTab: true,
        link: PAGE.GAME,
        route: `${PAGE.GAME}/${firstAlias.value}`,
        type: 'game'
      },
      {
        id: 'promotion',
        title: 'Khuyến Mãi',
        icon: '/assets/images/components/desktop/icon-promotion.png',
        loginRequired: false,
        newTab: false,
        type: 'lode',
        link: PAGE.PROMOTION,
        route: PAGE.PROMOTION
      }
    ]
  }
}
