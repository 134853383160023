<template>
  <div class="header-user">
    <div class="user-info">
      <div class="left-info">
        <p class="balance">{{ $formatNumberWithCommas(store.currentUser?.balance, '.') }} K</p>
        <div class="payment">
          <nuxt-link :to="PAGE.ACCOUNT_DEPOSIT_DA" class="payment__deposit">
            <BaseImg src="/assets/images/components/desktop/icon-deposit.svg" alt="bell" />
            <div>Nạp tiền</div>
          </nuxt-link>
          <nuxt-link :to="PAGE.ACCOUNT_WITHDRAW_BANK" class="payment__withdraw">Rút tiền</nuxt-link>
        </div>
      </div>
    </div>
    <client-only>
      <div class="user-block" @mouseleave="mouseLeave" @mouseover="mouseOver">
        <div class="user-menu" :class="{ active: isShowMenu }">
          <div class="avatar" @mouseleave="mouseLeave" @mouseover="mouseOver">
            <div class="avatar-name">
              <BaseImg
                :src="`/assets/images/components/desktop/avatar/${store.currentUser?.avatar}.webp`"
                alt="avatar"
              />
              <BaseImg
                :src="`${PATH_HEADER_MENU}hambuger_menu.svg`"
                alt="avatar"
                class="avatar_hambuger"
              />
            </div>
          </div>
          <div class="menu-dropdown">
            <div
              v-for="(item, index) in USER_MENU_HEADER"
              :id="item?.name"
              :key="index"
              class="menu-item"
              :class="{ active: item.activeUrl === route.fullPath }"
              @click="onClick(item)"
            >
              <BaseImg class="icon icon-inactive" :src="item?.icon?.inactive" alt="icon inactive" />
              <BaseImg class="icon icon-active" :src="item?.icon?.active" alt="icon active" />
              <div class="text">{{ item.name }}</div>
            </div>
          </div>
        </div>
      </div>
    </client-only>
  </div>
</template>
<script setup lang="ts">
import BaseImg from '~/components/common/base-img'
import { useLogout } from '~/composables/user/useLogout'
import { USER_MENU_HEADER } from '~/constants/menu'
import { useAppStore } from '~/store/app'
import { PAGE } from '~/constants/router'
import { IMenuUserDesktop } from '~/types/menu.type'
import { PATH_HEADER_MENU } from '~/constants/menu'

const { $pinia, $formatNumberWithCommas } = useNuxtApp()
const store = useAppStore($pinia)
const { logout } = useLogout()
const isShowMenu = ref(false)
const route = useRoute()

const truncatedFullName = computed(() => {
  const fullname = store.currentUser?.fullname || ''
  return fullname.length > 15 ? fullname.substring(0, 12) + '...' : fullname
})
const onClick = (item: IMenuUserDesktop) => {
  isShowMenu.value = false

  if (item.url === PAGE.ACCOUNT_DEPOSIT_DA) {
    onNavDeposit()
    return
  }
  if (item.url === PAGE.ACCOUNT_WITHDRAW_BANK) {
    onNavWithdraw()
    return
  }
  if (item.isLogout) {
    logout()
  } else {
    useRoute().path !== item.url && navigateTo(item.url)
  }
}

const mouseOver = () => {
  isShowMenu.value = true
}

const mouseLeave = () => {
  isShowMenu.value = false
}
const onNavDeposit = () => {
  if (useRoute().path !== PAGE.ACCOUNT_DEPOSIT_DA) {
    navigateTo(PAGE.ACCOUNT_DEPOSIT_DA)
  }
}
const onNavWithdraw = () => {
  if (useRoute().path === PAGE.ACCOUNT_WITHDRAW_BANK) {
    return
  }
  navigateTo(PAGE.ACCOUNT_WITHDRAW_BANK)
}
</script>
<style lang="scss" scoped src="assets/scss/components/desktop/header/user-logged.scss" />
