<template>
  <footer
    id="footer"
    class="section-footer"
  >
    <div class="container-custom footer">
      <div class="row-center">
        <nuxt-link to="/" class="logo">
          <BaseImg
            :src="LOGO_SITE_IMAGE"
            alt="logo"
            @click="goHome"
          />
        </nuxt-link>
        <div class="slogan_wrapper">
          <div class="seo-content__title" v-html="dataSeo?.content_title" />
          <div class="seo-content__description" v-html="dataSeo?.content_description" />
        </div>
      </div>
      <div class="footer-navigation">
        <div v-for="item in NAVIGATIONS" :key="item.title" class="footer-section">
          <p class="section-title">
            {{ item.title }}
          </p>
          <div v-if="item.subCategories.length < 5" class="section-wrapper" :class="{ 'button-wrapper': item?.type === 'ho-tro' }">
            <div
              v-for="(category, index) in item.subCategories"
              :key="index"
              class="section-container"
            >
              <nuxt-link v-if="category?.type === 'BUTTON'" class="button" :to="category.link || '/#'" :target="'_blank'">
                <BaseImg class="button__icon" :src="category.icon" :alt="category.title" />
                <p class="button__title">{{ category.title }}</p>
              </nuxt-link>
              <nuxt-link
                v-else
                :id="category.title"
                :key="category.title"
                class="category-name"
                :to="category.link || '/#'"
                :target="category?.type === 'BLANK' ? '_blank' : '_self'"
              >
                {{ category.title }}
              </nuxt-link>
            </div>
          </div>
          <div v-else>
            <div class="row-footer">
              <div class="column">
                <nuxt-link
                  v-for="category in item.subCategories.slice(0, 4)"
                  :id="category.title"
                  :key="category.title"
                  class="category-name"
                  :to="category.link"
                >
                  {{ category.title }}
                </nuxt-link>
              </div>
              <div class="column-right">
                <nuxt-link
                  v-for="category in item.subCategories.slice(4)"
                  :id="category.title"
                  :key="category.title"
                  class="category-name"
                  :to="category.link"
                >
                  {{ category.title }}
                </nuxt-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row-center footer-deposit">
        <nuxt-link
          v-for="item in DEPOSIT_LIST"
          :id="item.title"
          :key="item.title"
          :to="item?.link"
          class="deposit-item"
          @click="handleClickMethod(item)"
        >
          <BaseImg
            class="deposit-image"
            :src="item.image"
            :alt="item?.title"
          />
          <p class="deposit-title">{{ item.title }}</p>
        </nuxt-link>
      </div>
    </div>
    <div class="footer-reserve">
      <div class="container-custom">
        <div class="footer-reserve__content">
          <div class="footer-reserve__left">
            <nuxt-link
              title="DMCA.com Protection Status"
              target="_blank"
              to="//www.dmca.com/Protection/Status.aspx?ID=f362511c-0d21-4b76-af37-f633d9a377ba"
            >
              <BaseImg src="assets/images/components/desktop/footer/dmca.svg" class="dmca" alt="copy right" />
            </nuxt-link>
            <p class="reserve-text">{{ FOOTER_CONTENT.COPY_RIGHT }}</p>
          </div>

          <BaseImg
            src="assets/images/components/desktop/footer/copyright-logo.png"
            class="reserve-image"
            alt="copy right"
          />
        </div>
      </div>
    </div>
  </footer>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia'
import useModal from '~/composables/useModal'
import { useAppStore } from '~~/store/app'
import { PAGE_URLS } from '~/config/page-url'
import { DEPOSIT_LIST, footerContentData } from '@/constants/footer'
import BaseImg from '~/components/common/base-img'
import { LOGO_SITE_IMAGE } from '~~/constants/menu'
import { MODAL_TYPES } from '~/config/constant'
import { useFooterNavigation } from '~/composables/useFooter'
import { useContactStore } from '~/store/contact'
import useBase from '~/composables/useBase'

const { openModalWithNavigate } = useModal()
const router = useRouter()
const { $config, $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const { currentUser, seoData } = storeToRefs(store)
const { NAVIGATIONS } = useFooterNavigation()
const FOOTER_CONTENT = footerContentData()
const { fetchContact } = useContactStore($pinia)

const route = useRoute()
useBase()
const dataSeo = computed(() => {
  if (route.path === '/') {
    return seoData.value.find((item) => item.alias === '/')
  }
  if (route.name === 'khuyen-mai-id') {
    return seoData.value.find((item) => item.alias === 'khuyen-mai')
  }
  return seoData.value.find((item) => item.alias === route.path.slice(1)) ?? seoData.value.find((item) => item.alias === '/')
})

const handleClickMethod = (item:any) => {
  if (currentUser.value === null) {
    openModalWithNavigate(MODAL_TYPES.LOGIN, 'login', item?.link)
  } else {
    navigateTo(item?.link)
  }
}

const openLink = (url: string) => {
  switch (url) {
    case 'LIVECHAT':
      // TODO
      break
    case 'TELEGRAM_CSKH':
      window.open(useRuntimeConfig().TELEGRAM_CSKH, '_blank')
      break
  }
}

const goHome = () => {
  router.push({ path: PAGE_URLS.HOME })
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  })
}
useAsyncData(() => fetchContact())
</script>
<style lang="scss" scoped src="assets/scss/components/desktop/footer/index.scss" />
